"use client";
import React from "react";
import map_full from "images/map-full.png";
import map_mobile from "images/map-mobile.png";
import title_tail_icon from "images/title-tail.png";
import "styles/partner.scss";
import { Box, Flex } from "@chakra-ui/react";
import useUserStore from "../utils/commonStore";

const PartnerHead = ({ is_mobile_view }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="row"
    className="partner-head"
  >
    {is_mobile_view ? (
      <Flex flexDirection="column" alignItems="center">
        <h2>
          A <span className="light-text">constant partner</span> till you land
          in
        </h2>
        <Box width="10px" height="10px" />
        <h2 className="gradient-text">
          <span className="light-text">Abroad</span>
          <span className="title-tail">
            <img src={title_tail_icon} alt="trail-img" />
          </span>
        </h2>
      </Flex>
    ) : (
      <>
        <h2>
          A <span className="light-text">constant partner</span> till you land
          in
        </h2>
        <Box width="10px" height="10px" />
        <h2 className="gradient-text">
          <span className="light-text">USA</span>
          {/* <span className="title-tail">
            <img src={title_tail_icon} alt="trail-img" />
          </span> */}
        </h2>
      </>
    )}
  </Flex>
);

const Partner = () => {
  const { is_mobile_view } = useUserStore((state) => ({
    is_mobile_view: state.is_mobile_view,
  }));
  return (
    <div className="partner-container">
      <div className="partner-limiter">
        <PartnerHead is_mobile_view={is_mobile_view} />
        {is_mobile_view ? (
          <p className="partner-text">
            Never feel alone in the study abroad journey again. Our experts will
            work with you to develop a personalised strategy tailored to your needs.
          </p>
        ) : (
          <p className="partner-text">
            Never feel alone in the study abroad journey again. Our experts will
            work with <br />
            you to develop a personalised strategy tailored to your needs.
          </p>
        )}
        <img className="map-full-image" src={is_mobile_view ? map_mobile: map_full} alt="map-image" />
      </div>
    </div>
  );
};

export default Partner;
