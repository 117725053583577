// const base_url = 'http://localhost:5001'
const base_url = "https://api.dolfin.money/api";

const endpoints = {
  subscribe: {
    method: "POST",
    url: `${base_url}/subscribe`,
  },
  book_session: {
    method: "POST",
    url: `${base_url}/book_session/`,
  },
  share_feedback: {
    method: "POST",
    url: `${base_url}/feedback/`,
  },
  conversation: {
    method: "POST",
    url: `${base_url}/conversation/`,
  },
  generate_sop: {
    method: "POST",
    url: `${base_url}/sop/`,
  },
  get_sop: {
    method: "GET",
    url: `${base_url}/{{ID}}/get_sop/`,
  },
};

export default endpoints;
