import React from "react";
import {
  Box,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import useUserStore from "utils/commonStore";
import "styles/cowriter.scss";
import a_icon from "images/academic_icon.png";
import e_icon from "images/extra_icon.png";
import g_icon from "images/goals_icon.png";
import u_icon from "images/uni_icon.png";
import w_icon from "images/work_icon.png";

const COWStep0 = () => {
  const { setSOPCOWStep } = useUserStore((state) => ({
    setSOPCOWStep: state.setSOPCOWStep,
  }));

  const steps = [
    {
      icon: u_icon,
      title: "Personal & University details",
      step: 1,
    },
    {
      icon: g_icon,
      title: "Short-term and Long-term Goals",
      step: 2,
    },
    {
      icon: a_icon,
      title: "Academic Details",
      step: 3,
    },
    {
      icon: w_icon,
      title: "Work Experience",
      step: 4,
    },
    {
      icon: e_icon,
      title: "Extra-curricular Details",
      step: 5,
    },
  ];
  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <p className="cow-initial-step-text">
          We will inquire about your personal background and aspirations by
          posing a series of questions. These will include information regarding
          the university and program to which you are applying, your academic
          history, professional experience, extracurricular involvement, and
          other relevant details.
        </p>
        <p className="cow-initial-step-text">
          Once you're done, our Co-Writer algorithm generates a personalized SOP
          for you almost instantly and you can download the SOP in PDF.
        </p>
        <Box height="9px" />
        <Divider />
        <Box height="25px" />
        {steps.map((step, i) => (
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="cow-initial-step-row"
          >
            <Flex flexDirection="row" alignItems="center">
              <img
                src={step.icon}
                className="cow-initial-step-icon"
                alt="cowriter-head-icon"
              />
              <p className="cow-initial-step-title">{step.title}</p>
            </Flex>
            <p className="cow-initial-step-index">
              Step {step.step} of {steps.length}
            </p>
          </Flex>
        ))}
        <Flex flex={1} alignItems="flex-end" justifyContent="center">
          <DolfinButton
            color_button={true}
            text="Let’s Begin"
            shadow={false}
            onButtonClick={(e) => setSOPCOWStep("next")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default COWStep0;
