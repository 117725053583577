import {
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import sr_icon from "images/superrick-icon.svg";
import modal_back_icon from "images/modal-back.png";
import useUserStore from "utils/commonStore";
import "styles/cowriter.scss";
import COWStep1 from "components/cow/COWStep1";
import COWStep2 from "components/cow/COWStep2";
import { Link, useNavigate } from "react-router-dom";
import COWStep3 from "components/cow/COWStep3";
import COWStep4 from "components/cow/COWStep4";
import COWStep5 from "components/cow/COWStep5";
import COWStep0 from "components/cow/COWStep0";

const CoWriter = () => {
  const navigate = useNavigate();
  const { sop_cow_step, resetSOPCOWFormData } = useUserStore((state) => ({
    sop_cow_step: state.sop_cow_step,
    resetSOPCOWFormData: state.resetSOPCOWFormData,
  }));

  const renderForm = () => {
    if (sop_cow_step == 0) {
      return <COWStep0 />;
    } else if (sop_cow_step == 1) {
      return <COWStep1 />;
    } else if (sop_cow_step == 2) {
      return <COWStep2 />;
    } else if (sop_cow_step == 3) {
      return <COWStep3 />;
    } else if (sop_cow_step == 4) {
      return <COWStep4 />;
    } else if (sop_cow_step == 5) {
      return <COWStep5 />;
    }
  }

  const renderNote = () => {
    if ([1].includes(sop_cow_step)) {
      return (
        <Flex className="co-writer-feature-card">
          <img
            src={sr_icon}
            className="cowriter-head-img"
            alt="cowriter-head-icon"
          />
          <p className="co-writer-feature-card-text">
            Create your Statement of Purpose in just a few minutes. Quickly
            answer a few short questions about yourself, your academic and
            professional goals, and our AI will create personalized Statement of
            Purpose (SOP) for you.
          </p>
        </Flex>
      );
    }
  }

  const goBack = async () => {
    navigate('/');
    await resetSOPCOWFormData();
  }

  return (
    <Flex
      className="cowriter-holder"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex className="cowriter-limiter" flexDirection="column">
        <Flex
          className="modal-back-holder"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Flex
            className="modal-back-container"
            alignItems="center"
            flexDirection="row"
          >
            <Tooltip label="Go Back to Dolfin">
              <img
                src={modal_back_icon}
                height={44}
                className="modal-back-icon"
                alt="modal-back-icon"
                onClick={() => goBack()}
              />
            </Tooltip>
            <p className="cowriter-modal-breadcrumb">
              SOP <span className="gradient-text">Co-Writer</span>
            </p>
          </Flex>
        </Flex>

        {/* card */}
        {renderNote()}

        {/* form */}
        {renderForm()}
      </Flex>
    </Flex>
  );
};

export default CoWriter;
