"use client";
import React from "react";
import { Box } from "@chakra-ui/react";
import whatsapp_logo from "images/whatsapp-logo.png";
import "styles/components.scss";

export const WhatsappButton = ({
  shadow = true,
  onButtonClick = {},
  btn_icon = null,
  btn_top_text = null,
  btn_bottom_text = null,
}) => {
  let top_text = btn_top_text ? btn_top_text : "Chat on";
  let bottom_text = btn_bottom_text ? btn_bottom_text : "Whatsapp";

  return (
    <Box
      as="button"
      className="whatsapp_button"
      autoFocus={false}
      boxShadow={shadow ? "6px 6px 8px 0px rgba(0, 0, 0, 0.30);" : "none"}
      _hover={{
        borderColor: "#FFFFFFaa",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      onClick={() => onButtonClick()}
    >
      <img
        src={btn_icon ? btn_icon : whatsapp_logo}
        height={42}
        className="whatsapp_logo"
        alt="whatsapp-logo"
      />
      <p className="whatsapp_button_text">
        {top_text} <span className="whatsapp_text">{bottom_text}</span>
      </p>
    </Box>
  );
};

export const DolfinButton = ({
  text,
  small = false,
  xsmall = false,
  no_border = false,
  button_type = "button",
  light = false,
  shadow = true,
  color_button = false,
  onButtonClick = {},
  disabled = false
}) => {
  let extra_props = {
    as: "button",
  };
  if (small) {
    extra_props["fontSize"] = "18px";
    extra_props["height"] = "48px";
  }
  if (xsmall) {
    extra_props["fontSize"] = "16px";
    extra_props["height"] = "38px";
  }
  if (button_type) {
    extra_props["as"] = button_type;
  }
  if (disabled) {
    extra_props["_disabled"] = disabled;
  }

  if (color_button) {
    return (
      <Box
        fontSize="18px"
        height="48px"
        {...extra_props}
        className={`whatsapp_button ${no_border ? 'no_border' : ''}`}
        boxShadow={shadow ? "6px 6px 8px 0px rgba(0, 0, 0, 0.30);" : "none"}
        autoFocus={false}
        _hover={{
          borderColor: "#FFFFFFaa",
        }}
        _active={{
          transform: "scale(0.95)",
        }}
        onClick={() => onButtonClick()}
      >
        {text}
      </Box>
    );
  }
  return (
    <Box
      {...extra_props}
      className="dolfin_button"
      autoFocus={false}
      boxShadow={shadow ? "6px 6px 8px 0px rgba(0, 0, 0, 0.30);" : "none"}
      color={light ? "#121212" : "#FFFFFF"}
      borderColor="#C3C3C3"
      backgroundColor={light ? "#FFFFFF" : "#191919"}
      _hover={{
        borderColor: light ? "#000000" : "#FFFFFF",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      onClick={() => onButtonClick()}
    >
      {text}
    </Box>
  );
};
