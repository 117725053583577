"use client";
import React, { useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import user_icon from "images/user-icon.png";
import map_icon from "images/map-icon.png";
import mail_icon from "images/mail-icon.png";
import phone_icon from "images/phone-icon.png";
import x_icon from "images/x-icon.png";
import fb_icon from "images/fb-icon.png";
import in_icon from "images/in-icon.png";
import insta_icon from "images/insta-icon.png";
import "styles/footer.scss";
import logo from "images/logo.svg";
import student_image_n from "images/footer-graphic.png";
import { DolfinButton } from "components/Button";
import useUserStore from "utils/commonStore";
import { isValidEmail } from "utils/helpers";
import { Link } from "react-router-dom";
import { subscribeEmailAction } from "utils/modalActions";

const Footer = () => {
  const toast = useToast();
  const { is_mobile_view } = useUserStore((state) => ({
    is_mobile_view: state.is_mobile_view,
  }));

  const [subscribe_email, setSubscribeEmail] = useState("");

  const renderSocialMedia = () => {
    return (
      <Flex className="social-media-container">
        <img src={fb_icon} className="social-media-icon" alt="fb-icon" />
        <img src={x_icon} className="social-media-icon" alt="x-icon" />
        <img src={in_icon} className="social-media-icon" alt="in-icon" />
        <img src={insta_icon} className="social-media-icon" alt="insta-icon" />
      </Flex>
    );
  };
  const subscribe = () => {
    if (isValidEmail(subscribe_email)) {
      subscribeEmailAction(subscribe_email);
      setSubscribeEmail('')
    } else if (subscribe_email.trim().length === 0) {
      toast({
        title: "Please enter the email",
        status: "error",
        isClosable: true,
      });
    } else {
      toast({
        title: "Invalid Email",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <footer className="footer-container">
      <div className="footer-limiter">
        <div className="main-footer-content">
          <div className="footer-user-image-container">
            <img
              className="footer-user-image"
              src={student_image_n}
              alt="student-img"
            />
          </div>

          <Flex
            flexDirection={is_mobile_view ? "column" : "row"}
            alignItems={is_mobile_view ? "flex-start" : "center"}
            className="first-deck"
          >
            <div className="subscribe-why">
              <h4>
                Stay a <span className="light-text">step ahead</span> than{" "}
                <span className="light-text">others</span>
              </h4>
              <p className="subscribe-line">
                Subscribe to us for the latest news & updates
              </p>
            </div>
            <Flex
              className="subscribe-fields"
              flexDirection={is_mobile_view ? "column" : "row"}
              justifyContent="center"
            >
              <InputGroup className="email-field">
                <InputLeftElement pointerEvents="none">
                  <img
                    src={user_icon}
                    className="email-field-user-icon"
                    alt="user-icon"
                  />
                </InputLeftElement>
                <Input
                  type="email"
                  value={subscribe_email}
                  onChange={(e) => setSubscribeEmail(e.target.value)}
                  className="email-field-input"
                  placeholder="Enter your email"
                />
              </InputGroup>
              <DolfinButton
                color_button={true}
                text="Subscribe Now"
                onButtonClick={() => subscribe()}
              />
            </Flex>
          </Flex>
          <Flex
            className="second-deck"
            flexDirection={is_mobile_view ? "column" : "row"}
          >
            <div className="dopfin-details">
              <img src={logo} className="email-field-user-icon" alt="logo" />
              <p className="about-dolfin">
                We are a premier edu-fintech company dedicated to solving
                mentorship for students who want to go abroad.
              </p>
              {is_mobile_view ? renderSocialMedia() : <></>}
            </div>
            <Flex
              className="site-content"
              flexDirection={is_mobile_view ? "column" : "row"}
            >
              <div className="sitemap-container">
                <p className="sitemap-title">Products</p>
                <Link to="#" className="sitemap-link">
                  Study Abroad
                </Link>
                <Link to="#" className="sitemap-link">
                  Study in India
                </Link>
              </div>
              <div className="sitemap-container">
                <p className="sitemap-title">Contact Us</p>
                <div className="sitemap-item">
                  <img src={map_icon} className="sitemap-icon" alt="map-icon" />
                  <Link
                    to="https://maps.app.goo.gl/BT7BsXiubdTYe1fU8"
                    target="_blank"
                  >
                    <p>
                      Vaishnavi Tech Park, Sarjapur
                      <br />
                      Bellandur, Bengaluru, 560103
                    </p>
                  </Link>
                </div>
                <div className="sitemap-item">
                  <img
                    src={phone_icon}
                    className="sitemap-icon"
                    alt="phone-icon"
                  />
                  <Link to="tel:8197464993" target="_blank">
                    <p>8197464993</p>
                  </Link>
                  <p>,</p> <Box width="5px" />
                  <Link to="tel:7407656243" target="_blank">
                    <p>7407656243</p>
                  </Link>
                </div>
                <div className="sitemap-item">
                  <img
                    src={mail_icon}
                    className="sitemap-icon"
                    alt="mail-icon"
                  />
                  <Link to="mailto:hello@dolfin.world">
                    <p>hello@dolfin.world</p>
                  </Link>
                </div>
              </div>
            </Flex>
          </Flex>
          <Flex
            className="third-deck"
            flexDirection={is_mobile_view ? "column" : "row"}
          >
            <p className="cp-line">
              © {new Date().getFullYear()}. All rights reserved.
            </p>
            {is_mobile_view ? <></> : renderSocialMedia()}
          </Flex>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
