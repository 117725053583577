import { create } from "zustand";

const CommonStore = (set) => ({
  is_mobile_view: false,
  setMobileView: (val) => {
    set((state) => ({
      is_mobile_view: val,
    }));
  },
  is_loading: false,
  setLoading: (loading) => {
    set((state) => ({
      is_loading: loading,
    }));
  },
  nav_drawer_visible: false,
  setNavDrawerVisibility: (is_visible) => {
    set((state) => ({
      nav_drawer_visible: is_visible,
    }));
  },
  rick_modal_visible: true,
  setRickModalVisibility: (is_visible) => {
    set((state) => ({
      rick_modal_visible: is_visible,
    }));
  },
  thank_you_modal_visible: false,
  setThankYouModalVisibility: (is_visible) => {
    set((state) => ({
      thank_you_modal_visible: is_visible,
    }));
  },
  thank_you_title: "",
  thank_you_detail: "",
  setThankYouContext: (title, detail) => {
    set((state) => ({
      thank_you_title: title,
      thank_you_detail: detail,
    }));
  },
  book_modal_visible: false,
  setBookModalVisibility: (is_visible) => {
    set((state) => ({
      book_modal_visible: is_visible,
    }));
  },
  feedback_modal_visible: false,
  setFeedbackModalVisibility: (is_visible) => {
    set((state) => ({
      feedback_modal_visible: is_visible,
    }));
  },
  // sop form
  sop_cow_step: 0,
  setSOPCOWStep: (operation) => {
    set((state) => {
      let new_step = state.sop_cow_step;
      if (operation === "next") {
        new_step += 1;
      } else {
        new_step -= 1;
      }
      if (new_step < 0) {
        new_step = 0;
      }
      return {
        sop_cow_step: new_step,
      };
    });
  },
  sop_cow_form_data: {},
  resetSOPCOWFormData: () => {
    set((state) => ({
      sop_cow_form_data: {},
      sop_cow_step: 0
    }));
  },
  setSOPCOWFormData: (data) => {
    set((state) => ({
      sop_cow_form_data: { ...state.sop_cow_form_data, ...data },
    }));
  },
});

const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.debug("  applying", args);
      set(...args);
      console.debug("  new state", get());
    },
    get,
    api
  );

const useCommonStore = create(log(CommonStore));

export default useCommonStore;
