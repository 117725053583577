import { Flex } from '@chakra-ui/react'
import React from 'react'
import { DotLoader } from 'react-spinners'
import useUserStore from "../utils/commonStore";

const GlobalLoader = () => {
  const { is_loading } = useUserStore((state) => ({
    is_loading: state.is_loading,
  }));

  if (is_loading) {
    return (
      <Flex className='global-loader' alignItems="center" justifyContent="center">
        <DotLoader
          color="#72C34D"
          size={100}
          speedMultiplier={1}
        />
      </Flex>
    )
  }
  return <></>

}

export default GlobalLoader
