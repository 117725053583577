import useUserStore from "utils/commonStore";
import { bookSessionApi, shareFeedbackApi } from "./rest";

// subscribe
export const subscribeEmailAction = async (user_email) => {
  let is_successful = false;
  const setLoading = useUserStore.getState().setLoading;
  const setThankYouContext = useUserStore.getState().setThankYouContext;
  const setThankYouModalVisibility =
    useUserStore.getState().setThankYouModalVisibility;
  try {
    setLoading(true);
    // make an api call here
    setThankYouContext(
      "Thank you for subscribing",
      "We’ll keep you updated with latest news."
    );
    is_successful = true;
    setThankYouModalVisibility(true);
  } catch (error) {
    is_successful = false;
    console.error("error in subscribing");
    console.error(error);
  } finally {
    setLoading(false);
    return is_successful;
  }
};

// book a session
export const bookSessionAction = async (booking_data) => {
  let is_successful = false
  const setLoading = useUserStore.getState().setLoading;
  const setThankYouContext = useUserStore.getState().setThankYouContext;
  const setThankYouModalVisibility =
    useUserStore.getState().setThankYouModalVisibility;
  try {
    setLoading(true);
    await bookSessionApi(booking_data)
    // make an api call here
    setThankYouContext(
      "Thank you for your interest",
      "We will reach back out to you shortly."
    );
    is_successful = true
    setThankYouModalVisibility(true);
  } catch (error) {
    is_successful = false
    console.error("error in booking the session");
    console.error(error);
  } finally {
    setLoading(false);
    return is_successful
  }
};

// share feedback
export const shareFeedbackAction = async (feedback_data) => {
  let is_successful = false;
  const setLoading = useUserStore.getState().setLoading;
  const setThankYouContext = useUserStore.getState().setThankYouContext;
  const setThankYouModalVisibility =
    useUserStore.getState().setThankYouModalVisibility;
  try {
    setLoading(true);
    // make an api call here
    await shareFeedbackApi(feedback_data)
    setThankYouContext(
      "Thank you for sharing your feedback.",
      "We will reach back out to you shortly."
    );
    is_successful = true;
    setThankYouModalVisibility(true);
  } catch (error) {
    is_successful = false;
    console.error("error in sharing the feedback");
    console.error(error);
  } finally {
    setLoading(false);
    return is_successful;
  }
};
