"use client";
import React from "react";
import "styles/platform.scss";
import { SimpleGrid, Box, Flex } from "@chakra-ui/react";
import cc_icon from "images/cc-icon.png";
import expert_icon from "images/expert-icon.png";
import fast_icon from "images/fastest-icon.png";
import idfc_logo from "images/idfc.png";
import auxilo_logo from "images/auxilo.png";
import sbi_logo from "images/sbi.png";
import axis_logo from "images/axis.png";
import prodigy_logo from "images/prodigy.png";
import gd_logo from "images/gyandhan.png";
import bob_logo from "images/bob_s.png";
import kuhoo_logo from "images/kuhoo.png";
import zolve_logo from "images/zolve.png";
import united_bank_logo from "images/united_bank.png";
import useUserStore from "../utils/commonStore";

const PlatformHead = () => (
  <Flex alignItems="center" flexDirection="column">
    <h2>One Platform.</h2>
    <h2 className="gradient-text">A world of possibilities.</h2>
  </Flex>
);

const TrustHead = () => (
  <Flex alignItems="center" flexDirection="column" className="trust-heading">
    <h2>
      Trusted by
      <br /> global finance leaders.
    </h2>
  </Flex>
);

const Leaders = () => (
  <Flex
    className="platform-leaders"
    justifyContent="space-around"
    alignItems="center"
    wrap="wrap"
  >
    {[
      bob_logo,
      kuhoo_logo,
      gd_logo,
      zolve_logo,
      united_bank_logo,
      prodigy_logo,
    ].map((img, i) => (
      <img
        key={i}
        src={img}
        className="platform-leaders-logo"
        alt="leaders-logo"
      />
    ))}
  </Flex>
);

const Features = ({ is_mobile_view }) => (
  <SimpleGrid
    columns={is_mobile_view ? 1 : 3}
    spacing={"10px"}
    className="platform-features"
  >
    <Box className="platform-feature-container">
      <img src={fast_icon} className="platform-feature-icon-container" alt="fast-icon" />
      {is_mobile_view ? (
        <p className="platform-feature-text">
          Personalized and Expert mentoring
        </p>
      ) : (
        <p className="platform-feature-text">
          Personalized and Expert
          <br />
          mentoring
        </p>
      )}
      <p className="platform-feature-description">
        We've got everything covered, from strategy sessions, brainstorming, and
        outlining resume polishing to mock interviews!
      </p>
    </Box>
    <Box className="platform-feature-container">
      <img src={expert_icon} className="platform-feature-icon-container" alt="expert-icon" />
      {is_mobile_view ? (
        <p className="platform-feature-text">
          Fastest loan approval at best rates
        </p>
      ) : (
        <p className="platform-feature-text">
          Fastest loan approval
          <br />
          at best rates
        </p>
      )}
      <p className="platform-feature-description">
        Loan Approvals within 48 hours with best interest rates. We apply to
        lenders on your behalf while you sit back & relax!
      </p>
    </Box>
    <Box className="platform-feature-container">
      <img src={cc_icon} className="platform-feature-icon-container" alt="credit-card-icon" />
      {is_mobile_view ? (
        <p className="platform-feature-text">
          Get your credit card on Day 1
        </p>
      ) : (
      <p className="platform-feature-text">
        Get your credit card on
        <br />
        Day 1
      </p>)}
      <p className="platform-feature-description">
        Empower your new life without worrying about the financial complexities
        in a new land. Build your credit history from your first day and enjoy
        financial freedom.
      </p>
    </Box>
  </SimpleGrid>
);

const Platform = () => {
  const { is_mobile_view } = useUserStore((state) => ({
    is_mobile_view: state.is_mobile_view,
  }));
  return (
    <div className="platform-container">
      <div className="platform-limiter">
        <PlatformHead />
        <Features is_mobile_view={is_mobile_view} />
        <TrustHead />
        <Leaders />
      </div>
    </div>
  );
};

export default Platform;
