export const pusher_config = {
  APP_KEY: "625aae67f6c237e5e308",
  APP_CLUSTER: "ap2",
  channel: "user-doc",
  event_prefix: "sop-",
};

export const email_phone_regex =
  /^(?:(?:\+|00) ?(?:[1-9]\d{0,2})[- ]?)?(?:\d{10}|[\w\.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})$/gim;
export const email_regex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;

export const phone_regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gmi;
export const letter_regex = /[a-zA-Z]/gi;

export const dream_countries = [
  "Unites States of America",
  "Canada",
  "United Kingdom",
  "Australia",
  "New Zealand",
  "Ireland",
  "Singapore",
  "UAE",
];
export const programs = [
  "MBA",
  "Master of Science (MS)",
  "Bachelor of Science (BS)",
  "PhD",
  // "PGDip",
];
export const completed_programs = [
  "B.Sc.",
  "B.Tech",
  "B.A.",
  "BBA",
  "B.Com",
  "CA",
  "MA",
  "M.Sc.",
  "MBA",
  "M.Com",
  "PhD",
];
export const preferred_intakes = [
  "May 2024 - Summer",
  "September 2024 - Fall",
  "January 2025 - Spring",
  "September 2025 - Fall",
];
