"use client";
import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
  Divider,
} from "@chakra-ui/react";
import logo from "images/light-logo.svg";
import back from "images/back-arrow.png";
import home_icon from "images/home-icon-d.png";
import globe_icon from "images/globe-icon-d.png";
import map_icon from "images/map-icon-d.png";
import co_map_icon from "images/map-icon.png";
import co_mail_icon from "images/mail-icon.png";
import co_phone_icon from "images/phone-icon.png";
import { DolfinButton, WhatsappButton } from "./Button";
import useUserStore from "utils/commonStore";
import { Link } from "react-router-dom";
import "styles/components.scss";

const NavDrawer = () => {
  const { is_mobile_view, nav_drawer_visible, setNavDrawerVisibility } =
    useUserStore((state) => ({
      is_mobile_view: state.is_mobile_view,
      nav_drawer_visible: state.nav_drawer_visible,
      setNavDrawerVisibility: state.setNavDrawerVisibility,
    }));

  const renderTop = () => (
    <Flex flex={1} flexDirection="column">
      <div
        className="back-container"
        onClick={() => setNavDrawerVisibility(false)}
      >
        <img src={back} className="back-arrow" alt="back-arrow" />
      </div>
      <div className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </div>
      <Flex flexDirection="column" className="drawer-navs">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawer-nav-container active"
        >
          <div className="drawer-lead" />
          <Flex className="d-content" flexDirection="row" alignItems="center" onClick={() => setNavDrawerVisibility(false)}>
            <img src={home_icon} className="dicon" alt="home-icon" />
            <p className="d-nav-text">Home</p>
          </Flex>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawer-nav-container"
        >
          <Flex className="d-content" flexDirection="row" alignItems="center">
            <img src={globe_icon} className="dicon" alt="globe-icon" />
            <p className="d-nav-text">Study Abroad</p>
          </Flex>
          <p className="soon">Coming Soon</p>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawer-nav-container"
        >
          <Flex className="d-content" flexDirection="row" alignItems="center">
            <img src={map_icon} className="dicon" alt="map-icon" />
            <p className="d-nav-text">Study in India</p>
          </Flex>
          <p className="soon">Coming Soon</p>
        </Flex>
      </Flex>
    </Flex>
  );
  const renderBottom = () => (
    <Flex flexDirection="column">
      <Divider bg="#EDEDED" my="4" />
      <Flex flexDirection="row" className="hero-actions">
        <WhatsappButton shadow={false} onButtonClick={() => {}} />
        <Box width="26px" height="20px" />
        <DolfinButton
          text="Book a Session"
          shadow={false}
          light={true}
          onButtonClick={() => {}}
        />
      </Flex>
      <Divider bg="#EDEDED" my="4" />
      <div className="sitemap-container">
        <p className="sitemap-title">Contact Us</p>
        <div className="sitemap-item">
          <img src={co_map_icon} className="sitemap-icon" alt="map-icon" />
          <Link to="https://maps.app.goo.gl/BT7BsXiubdTYe1fU8" target="_blank">
            <p>
              Vaishnavi Tech Park, Sarjapur
              <br />
              Bellandur, Bengaluru, 560103
            </p>
          </Link>
        </div>
        <div className="sitemap-item">
          <img src={co_phone_icon} className="sitemap-icon" alt="phone-icon" />
          <Link to="tel:8197464993" target="_blank">
            <p>8197464993</p>
          </Link>
          <p>,</p> <Box width="5px" />
          <Link to="tel:7407656243" target="_blank">
            <p>7407656243</p>
          </Link>
        </div>
        <div className="sitemap-item">
          <img src={co_mail_icon} className="sitemap-icon" alt="mail-icon" />
          <Link to="mailto:hello@dolfin.world">
            <p>hello@dolfin.world</p>
          </Link>
        </div>
      </div>
    </Flex>
  );
  return (
    <Drawer
      isOpen={nav_drawer_visible}
      placement="right"
      autoFocus={false}
      onClose={() => setNavDrawerVisibility(false)}
      size="xs"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody className="drawer-body">
          {renderTop()}
          {renderBottom()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NavDrawer;
