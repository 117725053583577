"use client";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import useUserStore from "../utils/commonStore";
import { DolfinButton } from "./Button";
import "styles/components.scss";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { email_regex, phone_regex } from "utils/constants";
import { shareFeedbackAction } from "utils/modalActions";

const ShareFeedback = () => {
  const toast = useToast();
  const { is_mobile_view, feedback_modal_visible, setFeedbackModalVisibility } =
    useUserStore((state) => ({
      is_mobile_view: state.is_mobile_view,
      feedback_modal_visible: state.feedback_modal_visible,
      setFeedbackModalVisibility: state.setFeedbackModalVisibility,
    }));

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").required("Required"),
    email: Yup.string()
      .matches(email_regex, "Invalid Email")
      .required("Required"),
    phone: Yup.string()
      .matches(phone_regex, "Invalid Phone Number")
      .required("Required"),
    message: Yup.string().required("Required"),
  });

  const submitForm = async (form_data) => {
    const status = await shareFeedbackAction(form_data);
    setFeedbackModalVisibility(false);
    if (status) {
      toast({
        title: "Feedback Submitted",
        status: "success",
        isClosable: true,
      });
    } else {
      toast({
        title: "Something went wrong. Please try again",
        status: "error",
        isClosable: true,
      });
    }
  };

  const renderContent = () => (
    <ModalBody className="dolfin-modal-body">
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        className="modal-head"
      >
        <p className="title">Share your feedback</p>
        {is_mobile_view ? (
          <></>
        ) : (
          <p className="details">
            Hello, If you have any feedback or comments, please share them with
            us. It will help us build a better version of Dolfin.
          </p>
        )}
      </Flex>
      <div className="modal-fields">
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form>
              {/* name */}
              <Field name="name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">Name</FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* email */}
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Email
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* phone */}
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Phone Number
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* message */}
              <Field name="message">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.message && form.touched.message}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems="flex-start"
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Message
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Textarea
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          placeholder=""
                        />
                        <FormErrorMessage>
                          {form.errors.message}
                        </FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>

              <Flex className="modal-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Cancel"
                  small={true}
                  button_type="reset"
                  onButtonClick={() => setFeedbackModalVisibility(false)}
                />
                <Box width="30px" />
                <DolfinButton
                  shadow={false}
                  text="Submit"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </div>
    </ModalBody>
  );

  return (
    <>
      <Modal
        isOpen={feedback_modal_visible}
        onClose={() => setFeedbackModalVisibility(false)}
      >
        <ModalOverlay />
        <ModalContent
          maxH={is_mobile_view ? "none" : "800px"}
          maxW={is_mobile_view ? "calc(100vw - 30px)" : "750px"}
        >
          {renderContent()}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareFeedback;
