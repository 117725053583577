"use client";
import React from "react";
import { Box, Divider, Flex } from "@chakra-ui/react";
import "styles/mission.scss";
import whatsapp_logo from "images/whatsapp-logo.png";
import hello_text from "images/hello.svg";
import students_img from "images/students_n.png";
import feature_icon from "images/featured-icon.png";
import { DolfinButton, WhatsappButton } from "../components/Button";
import useUserStore from "../utils/commonStore";
import { Link } from "react-router-dom";

const MissionHead = () => (
  <Flex alignItems="center" flexDirection="column">
    <h2>Our Mission.</h2>
    <h2 className="gradient-text">Empower every student.</h2>
  </Flex>
);

const Hello = ({ is_mobile_view, setFeedbackModalVisibility }) => {
  return (
    <Flex
      className="hello-container"
      flexDirection={is_mobile_view ? "column" : "row"}
    >
      <Flex
        flexDirection="column"
        alignItems={is_mobile_view ? "center" : "flex-start"}
        justifyContent="center"
      >
        <img src={feature_icon} className="hello-icon" alt="hello-img" />
        <h2 className="small">We’re just a text away.</h2>
        <h2 className="small gradient-text">Get in touch.</h2>
        {is_mobile_view ? (
          <Divider marginTop="20px" marginBottom="20px" />
        ) : (
          <></>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        className="hello-content"
      >
        {is_mobile_view ? (
          <Flex className="hello-say" flexDirection="row">
            Say
            <span>
              <img
                src={hello_text}
                height={26}
                className="hello-text"
                alt="hello-text"
              />
            </span>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              to us on
              <span>
                <img
                  src={whatsapp_logo}
                  height={42}
                  className="whatsapp-logo"
                  alt="whatsapp-logo"
                />
              </span>
              <span className="whatsapp-text">Whatsapp</span>
            </Flex>
          </Flex>
        ) : (
          <h3 className="hello-say">
            Say{" "}
            <span>
              <img
                src={hello_text}
                height={55}
                className="hello-text"
                alt="hello-text"
              />
            </span>{" "}
            to us on
            <span>
              <img
                alt="whatsapp-logo"
                src={whatsapp_logo}
                height={42}
                className="whatsapp-logo"
              />
            </span>
            <span className="whatsapp-text">Whatsapp</span>
          </h3>
        )}

        <p className="hello-description">
          Connect with us on Whatsapp and get the updates on education trends,
          financial advice, scholarships, events and much more
        </p>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="normal"
        justifyContent="center"
        className="mission-actions"
      >
        <Link
          to="https://api.whatsapp.com/send/?phone=918197464993&text&type=phone_number&app_absent=0"
          target="_blank"
        >
          <WhatsappButton shadow={false} onButtonClick={() => {}} />
        </Link>
        <Box height="12px" width="10px" />
        <DolfinButton
          light={true}
          shadow={false}
          text="Share Feedback"
          onButtonClick={() => setFeedbackModalVisibility(true)}
        />
      </Flex>
    </Flex>
  );
};

const Mission = () => {
  const { is_mobile_view, setFeedbackModalVisibility } = useUserStore(
    (state) => ({
      is_mobile_view: state.is_mobile_view,
      setFeedbackModalVisibility: state.setFeedbackModalVisibility,
    })
  );
  return (
    <div className="mission-container">
      <div className="mission-limiter">
        <MissionHead />
        <Flex
          className="mission-content"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={students_img}
            className="platform-leaders-logo"
            alt="students-group-img"
          />
          <p className="mission-content-text">
            Empower every student to find their passion while developing
            valuable life skills for college and beyond. We motivate students to
            view education as a path to realise their potential and make an
            impact on the world.
          </p>
        </Flex>
        <Hello
          is_mobile_view={is_mobile_view}
          setFeedbackModalVisibility={setFeedbackModalVisibility}
        />
      </div>
    </div>
  );
};

export default Mission;
