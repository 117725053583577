import useUserStore from "utils/commonStore";
import { conversationApi, generateSOPApi, getGeneratedSOPApi } from "./rest";

// conversation - superrick
export const conversationAction = async (user_query) => {
  let superrick_response = "";
  const setLoading = useUserStore.getState().setLoading;
  try {
    setLoading(true);
    // make an api call here
    superrick_response = await conversationApi(user_query);
  } catch (error) {
    console.error("error in superrick query");
    console.error(error);
  } finally {
    setLoading(false);
    return superrick_response;
  }
};

// superrick - sop co-writer
export const submitSOPAction = async (user_data) => {
  let sop_generated_id = "";
  const setLoading = useUserStore.getState().setLoading;
  try {
    setLoading(true);
    const {
      // step 1
      name,
      email,
      phone,
      college_name,
      country,
      program,
      course,
      background_details,
      why_university,
      why_program,
      // step 2
      short_term_goals,
      long_term_goals,
      // step 3
      highest_degree,
      major,
      attended_university_name,
      university_gpa,
      fav_courses,
      top_2_projects,
      top_2_achievements,
      // step 4
      org_name,
      org_type,
      work_position,
      roles_and_responsibilities,
      projects_completed,
      skills_learned,
      // step 5
      club_name,
      club_description,
      extra_position,
      extra_roles_and_responsibilities,
      community_services,
      awards_and_achievements,
    } = user_data

    const payload = {
      data: {
        personal: {
          name,
          email,
          mobile: phone,
          college: college_name,
          country,
          program,
          course,
          background: background_details,
        },
        goals: {
          why_this_college: why_university,
          why_this_program: why_program,
          short_term_goals,
          long_term_goals,
        },
        academic_details: {
          highest_degree,
          field_of_study: major,
          gpa: university_gpa,
          university: attended_university_name,
          favourite_courses: fav_courses,
          top_projects: top_2_projects,
          top_acad_achievemnts: top_2_achievements,
        },
        work_ex: {
          org_name,
          org_type,
          position: work_position,
          responsibilities: roles_and_responsibilities,
          projects: projects_completed,
          skills: skills_learned,
        },
        extra_curricular: {
          org_name: club_name,
          description: club_description,
          position: extra_position,
          responsibilities: extra_roles_and_responsibilities,
          community_services,
          awards: awards_and_achievements,
          achievements: "",
        },
      },
    };

    // for (const key in payload) {
    //   for (const inner_key in payload[key]) {
    //     if (payload[key][inner_key] == '' || payload[key][inner_key] == undefined || payload[key][inner_key] === null) {
    //       payload[key][inner_key] = '-'
    //     }
    //   }
    // }

    // make an api call here
    sop_generated_id = await generateSOPApi(payload);
  } catch (error) {
    console.error("error in sop submission");
    console.error(error);
  } finally {
    setLoading(false);
    return sop_generated_id;
  }
};

//
export const getSOPResultAction = async (sop_generated_id) => {
  let content = "";
  const setLoading = useUserStore.getState().setLoading;
  try {
    setLoading(true);
    // make an api call here
    content = await getGeneratedSOPApi(sop_generated_id);
  } catch (error) {
    console.error("error in sop fetch");
    console.error(error);
  } finally {
    setLoading(false);
    return content;
  }
};
