import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import { Field, Form, Formik } from "formik";
import useUserStore from "utils/commonStore";
import * as Yup from "yup";
import {
  completed_programs,
  dream_countries,
  email_regex,
  phone_regex,
  programs,
} from "utils/constants";
import "styles/cowriter.scss";
import { useState } from "react";
import ValueTracker from "./ValueTracker";

const COWStep3 = () => {
  const initial_form_values = {
    highest_degree: "",
    major: "",
    attended_university_name: "",
    university_gpa: "",
    fav_courses: "",
    top_2_projects: "",
    top_2_achievements: "",
  };
  const [initial_values, setInitialValues] = useState(initial_form_values);
  const { sop_cow_form_data, setSOPCOWFormData, setSOPCOWStep } = useUserStore(
    (state) => ({
      sop_cow_form_data: state.sop_cow_form_data,
      setSOPCOWFormData: state.setSOPCOWFormData,
      setSOPCOWStep: state.setSOPCOWStep,
    })
  );

  useEffect(() => {
    if (sop_cow_form_data && Object.keys(sop_cow_form_data).length > 0) {
      setInitialValues(sop_cow_form_data);
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    highest_degree: Yup.string().required(),
    major: Yup.string().required(),
    attended_university_name: Yup.string().required(),
    university_gpa: Yup.number().required(),
    fav_courses: Yup.string().required(),
    top_2_projects: Yup.string(),
    top_2_achievements: Yup.string(),
  });

  const submitForm = async (form_data) => {
    setSOPCOWFormData(form_data);
    setSOPCOWStep("next");
  };

  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h5 className="cow-title">Academic details</h5>
        <p className="cow-step-indicator">Step 3 of 5</p>
      </Flex>

      <Flex flex={1} flexDirection="column" className="cow-form-frame">
        <Formik
          initialValues={initial_values}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form className="cow-form">
              <SimpleGrid columns={3} spacing={"12px"} className="">
                {/* highest degree */}
                <Field name="highest_degree">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.highest_degree &&
                        form.touched.highest_degree
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Your Highest Degree
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Select
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder="Select option"
                          >
                            {completed_programs.map((program) => (
                              <option key={program} value={program}>
                                {program}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.highest_degree}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* major */}
                <Field name="major">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.major && form.touched.major}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Your Major / Field of Study
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.major}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* fav courses */}
                <Field name="fav_courses">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.fav_courses && form.touched.fav_courses
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Relevant / Favorite Courses
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.fav_courses}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>

                {/* university gpa */}
                <Field name="attended_university_name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.attended_university_name &&
                        form.touched.attended_university_name
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            University
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.attended_university_name}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>

                {/* university */}
                <Field name="university_gpa">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.university_gpa &&
                        form.touched.university_gpa
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            University GPA
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.university_gpa}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <SimpleGrid columns={1} spacing={"12px"} className="cow-grid-2">
                {/* top_2_projects */}
                <Field name="top_2_projects">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.top_2_projects &&
                        form.touched.top_2_projects
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Tell about your top 2 projects that you completed in
                            your college / university
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.top_2_projects}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* top_2_achievements */}
                <Field name="top_2_achievements">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.top_2_achievements &&
                        form.touched.top_2_achievements
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Share your top 2 academic achievements
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.top_2_achievements}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>

              <Flex className="cow-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Back"
                  xsmall={true}
                  onButtonClick={() => setSOPCOWStep("back")}
                />
                <Box width="16px" />
                <DolfinButton
                  no_border={true}
                  xsmall={true}
                  shadow={false}
                  text="Next"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
              <ValueTracker />
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default COWStep3;
