"use client";
import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "components/Navbar";
import { DolfinButton, WhatsappButton } from "components/Button";
import counselling_icon from "images/counselling-icon.png";
import creditcard_icon from "images/creditcard-icon.png";
import student_image_n from "images/student_s.png";
import student_image_m from "images/user-mobile.png";
import fast_icon from "images/fast-icon.png";
import "styles/hero.scss";
import useUserStore from "../utils/commonStore";
import rick_logo from "images/superrick-icon.svg";
import { Link } from "react-router-dom";

const Features = ({ is_mobile_view }) => {
  return (
    <Flex className="hero-features">
      <Box className="hero-feature-container">
        <img
          src={counselling_icon}
          className="hero-feature-icon-container"
          alt="counselling-icon"
        />
        {is_mobile_view ? (
          <p className="hero-feature-text">Personalized and Expert mentoring</p>
        ) : (
          <p className="hero-feature-text">
            Personalized
            <br />
            and Expert
            <br />
            mentoring
          </p>
        )}
      </Box>
      <Box className="hero-feature-container">
        <img
          src={fast_icon}
          className="hero-feature-icon-container"
          alt="fast-icon"
        />
        {is_mobile_view ? (
          <p className="hero-feature-text">
            Fastest loan approval at best rates
          </p>
        ) : (
          <p className="hero-feature-text">
            Fastest loan
            <br />
            approval at best
            <br />
            rates
          </p>
        )}
      </Box>
      <Box className="hero-feature-container">
        <img
          src={creditcard_icon}
          className="hero-feature-icon-container"
          alt="credit-card-icon"
        />
        {is_mobile_view ? (
          <p className="hero-feature-text">Get your credit card from day one</p>
        ) : (
          <p className="hero-feature-text">
            Get your credit
            <br />
            card from
            <br />
            day one
          </p>
        )}
      </Box>
    </Flex>
  );
};

const HeroContent = ({
  is_mobile_view,
  setBookModalVisibility,
  setRickModalVisibility,
}) => {
  return (
    <div className="hero-content">
      {is_mobile_view ? (
        <h1>
          Making <span className="bold-title">quality education</span>
          <br /> accessible to everyone
        </h1>
      ) : (
        <>
          <h1>
            Making <span className="bold-title">quality education</span>
          </h1>
          <h1>
            <span className="bold-title">accessible</span> to everyone
          </h1>
        </>
      )}
      <h3>Reliable partner to fulfil your study abroad dream</h3>
      <Flex
        className="hero-actions"
        justifyContent={is_mobile_view ? "space-around" : "flex-start"}
      >
        <DolfinButton
          text="Book a Session"
          onButtonClick={() => setBookModalVisibility(true)}
        />
        <Box width="26px" height="20px" />
        {is_mobile_view ? (
          <Link
            to="https://api.whatsapp.com/send/?phone=918197464993&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <WhatsappButton onButtonClick={() => {}} />
          </Link>
        ) : (
          <Link to="/talk-to-superrick">
            <WhatsappButton
              onButtonClick={() => {}}
              btn_icon={rick_logo}
              btn_top_text="Talk to"
              btn_bottom_text="SuperRick"
            />
          </Link>
        )}
      </Flex>
      {is_mobile_view ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          className="mobile-user-img-container"
        >
          <img
            className="hero-user-image"
            src={student_image_m}
            alt="student-image-mobile"
            placeholder="empty"
          />
        </Flex>
      ) : (
        <></>
      )}
    </div>
  );
};

const Hero = () => {
  const { is_mobile_view, setBookModalVisibility, setRickModalVisibility } =
    useUserStore((state) => ({
      is_mobile_view: state.is_mobile_view,
      setBookModalVisibility: state.setBookModalVisibility,
      setRickModalVisibility: state.setRickModalVisibility,
    }));
  return (
    <div className="hero-container">
      <div className="hero-limiter">
        <Navbar />
        <div className="main-hero-content">
          {is_mobile_view ? (
            <></>
          ) : (
            <div className="hero-user-image-container">
              <img
                className="hero-user-image"
                src={student_image_n}
                alt="hero-image-web"
                placeholder="empty"
              />
            </div>
          )}
          <Features is_mobile_view={is_mobile_view} />
          <HeroContent
            is_mobile_view={is_mobile_view}
            setBookModalVisibility={setBookModalVisibility}
            setRickModalVisibility={setRickModalVisibility}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
