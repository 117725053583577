import React, { useEffect, useState } from "react";
import { Flex, Spinner, Tooltip, useToast } from "@chakra-ui/react";
import success_icon from "images/success-icon.png";
import modal_back_icon from "images/modal-back.png";
import "styles/cowriter.scss";
import parse from "html-react-parser";
import { Link, useParams } from "react-router-dom";
import { getSOPResultAction } from "utils/actions";
import GlobalLoader from "components/GlobalLoader";
import Pusher from "pusher-js";
import { pusher_config } from "utils/constants";

const CoWriterResult = () => {
  let { sopId } = useParams();
  const toast = useToast();
  const [content, setContent] = useState("");
  const [pusher_client, setPusherClient] = useState(null);

  useEffect(() => {
    if (!pusher_client) {
      const pusher = new Pusher(pusher_config.APP_KEY, {
        cluster: pusher_config.APP_CLUSTER,
      });
      setPusherClient(pusher);
    }
  });

  if (pusher_client) {
    const channel = pusher_client.subscribe(pusher_config.channel);
    console.debug(`subscribing to -> ${pusher_config.channel} <-`);
    const event = pusher_config.event_prefix + sopId;
    console.debug(`event -> ${event} <-`);
    channel.bind(event, (data) => {
      console.debug("incoming data from pusher ...");
      console.debug(data);
      setContent(data.message);
    });
  }

  // on demand api workflow
  useEffect(() => {
    fetchSOPDoc(sopId);
  }, [sopId]);

  const fetchSOPDoc = async (sopId) => {
    const content = await getSOPResultAction(sopId);
    if (content.length == 0) {
      toast({
        title:
          "SOP Generation is in progress. Please refresh the page after sometime",
        status: "info",
        isClosable: true,
      });
    } else {
      setContent(content);
    }
  };

  return (
    <>
      <GlobalLoader />
      <Flex
        className="cowriter-holder"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex className="cowriter-limiter" flexDirection="column">
          <Flex
            className="modal-back-holder"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
          >
            <Flex
              className="modal-back-container"
              alignItems="center"
              flexDirection="row"
            >
              <Tooltip label="Generate Another SOP">
                <Link to="/co-writer">
                  <img
                    src={modal_back_icon}
                    height={44}
                    className="modal-back-icon"
                    alt="modal-back-icon"
                    onClick={() => {}}
                  />
                </Link>
              </Tooltip>
              <p className="cowriter-modal-breadcrumb">
                SOP <span className="gradient-text">Co-Writer</span>
              </p>
            </Flex>
          </Flex>

          {/* card */}
          {content ? (
            <Flex className="co-writer-feature-card-done" alignItems="center">
              <img
                src={success_icon}
                className="cowriter-head-img"
                alt="cowriter-head-icon"
              />
              <p className="co-writer-feature-card-text">
                Congratulations! Co-Writer has successfully created your SOP.
              </p>
            </Flex>
          ) : (
            <></>
          )}

          {/* result */}
          <Flex className="co-writer-feature-card-done-text">
            <p className="cow-result-text">
              {content ? (
                parse(content)
              ) : (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner />
                  <p className="sop-progress-note">
                    SOP Generation is in progress. Please refresh the page after
                    sometime
                  </p>
                </Flex>
              )}
            </p>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default CoWriterResult;
