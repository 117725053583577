import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import { Field, Form, Formik } from "formik";
import useUserStore from "utils/commonStore";
import * as Yup from "yup";
import {
  dream_countries
} from "utils/constants";
import "styles/cowriter.scss";
import { useState } from "react";
import ValueTracker from "./ValueTracker";

const COWStep4 = () => {
  const initial_form_values = {
    org_name: "",
    org_type: "",
    work_position: "",
    roles_and_responsibilities: "",
    projects_completed: "",
    skills_learned: "",
  };
  const [initial_values, setInitialValues] = useState(initial_form_values);
  const { sop_cow_form_data, setSOPCOWFormData, setSOPCOWStep } = useUserStore(
    (state) => ({
      sop_cow_form_data: state.sop_cow_form_data,
      setSOPCOWFormData: state.setSOPCOWFormData,
      setSOPCOWStep: state.setSOPCOWStep,
    })
  );

  useEffect(() => {
    if (sop_cow_form_data && Object.keys(sop_cow_form_data).length > 0) {
      setInitialValues(sop_cow_form_data);
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    org_name: Yup.string(),
    org_type: Yup.string(),
    work_position: Yup.string(),
    roles_and_responsibilities: Yup.string(),
    projects_completed: Yup.string(),
    skills_learned: Yup.string(),
  });

  const submitForm = async (form_data) => {
    setSOPCOWFormData(form_data);
    setSOPCOWStep("next");
  };

  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h5 className="cow-title">Work Experience</h5>
        <p className="cow-step-indicator">Step 4 of 5</p>
      </Flex>

      <Flex flex={1} flexDirection="column" className="cow-form-frame">
        <Formik
          initialValues={initial_values}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form className="cow-form">
              <SimpleGrid columns={3} spacing={"12px"} className="">
                {/* org name */}
                <Field name="org_name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.org_name && form.touched.org_name}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Organization Name
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.org_name}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* org type */}
                <Field name="org_type">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.org_type && form.touched.org_type}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Organization Type
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.org_type}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* position */}
                <Field name="work_position">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.work_position && form.touched.work_position
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Position
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.work_position}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <SimpleGrid columns={1} spacing={"12px"} className="cow-grid-2">
                {/* roles_and_responsibilities */}
                <Field name="roles_and_responsibilities">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.roles_and_responsibilities &&
                        form.touched.roles_and_responsibilities
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Roles and Responsibilties
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.roles_and_responsibilities}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* projects_completed */}
                <Field name="projects_completed">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.projects_completed &&
                        form.touched.projects_completed
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            What projects did you complete as part of this role?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.projects_completed}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* skills_learned */}
                <Field name="skills_learned">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.skills_learned &&
                        form.touched.skills_learned
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            What skills did you learn in this role?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.skills_learned}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>

              <Flex className="cow-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Back"
                  xsmall={true}
                  onButtonClick={() => setSOPCOWStep("back")}
                />
                <Box width="16px" />
                <DolfinButton
                  no_border={true}
                  xsmall={true}
                  shadow={false}
                  text="Next"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
              <ValueTracker />
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default COWStep4;
