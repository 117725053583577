import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import { Field, Form, Formik } from "formik";
import useUserStore from "utils/commonStore";
import * as Yup from "yup";
import {
  dream_countries
} from "utils/constants";
import "styles/cowriter.scss";
import { useState } from "react";
import ValueTracker from "./ValueTracker";
import { submitSOPAction } from "utils/actions";
import { useNavigate } from "react-router-dom";

const COWStep5 = () => {
  const navigate = useNavigate();

  const initial_form_values = {
    club_name: "",
    club_description: "",
    extra_position: "",
    extra_roles_and_responsibilities: "",
    community_services: "",
    awards_and_achievements: "",
  };
  const [initial_values, setInitialValues] = useState(initial_form_values);
  const {
    sop_cow_form_data,
    setSOPCOWFormData,
    resetSOPCOWFormData, setSOPCOWStep,
  } = useUserStore((state) => ({
    sop_cow_form_data: state.sop_cow_form_data,
    setSOPCOWFormData: state.setSOPCOWFormData,
    resetSOPCOWFormData: state.resetSOPCOWFormData,
    setSOPCOWStep: state.setSOPCOWStep,
  }));

  useEffect(() => {
    if (sop_cow_form_data && Object.keys(sop_cow_form_data).length > 0) {
      setInitialValues(sop_cow_form_data);
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    club_name: Yup.string(),
    club_description: Yup.string(),
    extra_position: Yup.string(),
    extra_roles_and_responsibilities: Yup.string(),
    community_services: Yup.string(),
    awards_and_achievements: Yup.string(),
  });

  const submitForm = async (form_data) => {
    setSOPCOWFormData(form_data);
    const sop_generated_id = await submitSOPAction(form_data);
    navigate(`/co-writer/${sop_generated_id}`);
    resetSOPCOWFormData();
  };

  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h5 className="cow-title">Extra-curricular Details</h5>
        <p className="cow-step-indicator">Step 5 of 5</p>
      </Flex>

      <Flex flex={1} flexDirection="column" className="cow-form-frame">
        <Formik
          initialValues={initial_values}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form className="cow-form">
              <SimpleGrid columns={3} spacing={"12px"} className="">
                {/* club name */}
                <Field name="club_name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.club_name && form.touched.club_name
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Organization/Club Name
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.club_name}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* club description */}
                <Field name="club_description">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.club_description &&
                        form.touched.club_description
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Description
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.club_description}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* position */}
                <Field name="extra_position">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.extra_position &&
                        form.touched.extra_position
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Position
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.extra_position}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <SimpleGrid columns={1} spacing={"12px"} className="cow-grid-2">
                {/* extra_roles_and_responsibilities */}
                <Field name="extra_roles_and_responsibilities">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.extra_roles_and_responsibilities &&
                        form.touched.extra_roles_and_responsibilities
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Roles and Responsibilties
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.extra_roles_and_responsibilities}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* community_services */}
                <Field name="community_services">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.community_services &&
                        form.touched.community_services
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Community Services
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.community_services}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* awards_and_achievements */}
                <Field name="awards_and_achievements">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.awards_and_achievements &&
                        form.touched.awards_and_achievements
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Awards and Achivements
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.awards_and_achievements}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>

              <Flex className="cow-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Back"
                  xsmall={true}
                  onButtonClick={() => setSOPCOWStep("back")}
                />
                <Box width="16px" />
                <DolfinButton
                  no_border={true}
                  xsmall={true}
                  shadow={false}
                  text="Submit"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
              <ValueTracker />
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default COWStep5;
