import BookSession from "components/BookSession";
import ShareFeedback from "components/ShareFeedback";
import GlobalLoader from 'components/GlobalLoader';
import NavDrawer from 'components/NavDrawer';
import ResoHelper from 'components/ResoHelper';
import ThankYouModal from "components/ThankYouModal";
import Footer from 'layouts/Footer';
import Hero from 'layouts/Hero';
import Mission from 'layouts/Mission';
import Partner from 'layouts/Partner';
import Platform from 'layouts/Platform';
import "styles/mobile_view.scss";

const Home = () => {
  return (
    <>
      <GlobalLoader />
      <ThankYouModal />
      <BookSession />
      <ShareFeedback />
      <NavDrawer />
      <ResoHelper />
      {/* sections */}
      <Hero />
      <Platform />
      <Partner />
      <Mission />
      <Footer />
    </>
  );
}

export default Home;
