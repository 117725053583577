import {
  Box,
  Flex,
  Input,
  Tooltip,
  Modal,
  Spinner,
  ModalContent,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import React, { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";
import sr_icon from "images/superrick-icon.svg";
import sr_user_icon from "images/superrick-user-icon.svg";
import sr_prompt_icon from "images/superrick-prompt-arrow.png";
import modal_back_icon from "images/modal-back.png";
import { conversationAction } from "utils/actions";
import useUserStore from "utils/commonStore";
import "styles/superrick.scss";
import { Link, useNavigate } from "react-router-dom";

const SuperRick = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [chat_session, setChatSession] = useState([]);
  const [user_input_text, setUserInputText] = useState("");

  const { is_loading, rick_modal_visible, setRickModalVisibility } =
    useUserStore((state) => ({
      is_loading: state.is_loading,
      rick_modal_visible: state.rick_modal_visible,
      setRickModalVisibility: state.setRickModalVisibility,
    }));

  const last_chat_box = useRef();
  useEffect(() => {
    scrollToBottom();
  }, [chat_session]);

  const scrollToBottom = () => {
    if (last_chat_box && last_chat_box.current) {
      last_chat_box.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const requestDolfin = async (user_input) => {
    const dolfin_response = await conversationAction(user_input);
    if (dolfin_response.length == 0) {
      toast({
        title: "Something went wrong. Please try again",
        status: "error",
        isClosable: true,
      });
    } else {
      setChatSession((prev_chat) => [
        ...prev_chat,
        { from: "dolfin", text: dolfin_response },
      ]);
    }
  };

  const handleUserRequest = async (e, value) => {
    if (e) {
      e.preventDefault();
    }
    let user_input = value;
    if (!user_input || (user_input && user_input.length === 0)) {
      user_input = user_input_text;
    }
    if (is_loading || user_input.trim().length === 0) {
      return null;
    }
    setChatSession((prev_chat) => [
      ...prev_chat,
      { from: "user", text: user_input },
    ]);
    setUserInputText("");
    await requestDolfin(user_input);
  };

  const exitSuperRick = () => {
    setChatSession([]);
    setRickModalVisibility(false);
  };

  const onPromptClick = async (prompt) => {
    if (prompt === "cow") {
      navigate("/co-writer");
    } else {
      await handleUserRequest(null, prompt);
    }
  };

  const renderPrompts = () => {
    const predefined_prompts = [
      {
        title: "College Selection",
        text: "Help you selecting the right programs, comparing destination countries and more.",
        prompt: "SuperRick, help me with the college selection process",
      },
      {
        title: "Sample SOP",
        text: "Generate a sample SOP for MS in Computer Science",
        prompt: "Generate a sample SOP for MS in Computer Science for CMU",
      },
      {
        title: "VISA process + Mock Interview",
        text: "Help you in conducting mock interview for your visa applications.",
        prompt: "SuperRick, help me with the visa process and mock interviews",
      },
      {
        title: "Financing your education",
        text: "Help you finding the Loan partner who can finance your expenses at the best rates.",
        prompt: "SuperRick, help me with financing the education",
      },
    ];
    return (
      <Flex
        className="pre-prompts-holder"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={sr_icon}
          className="superrick-head-img"
          alt="superrick-dolfin-icon"
        />
        <h3 className="superrick-title">SuperRick</h3>
        <p className="superrick-greeting">How can I help you today?</p>
        <SimpleGrid columns={2} spacing={"12px"} className="pre-prompts-grid">
          {predefined_prompts.map((_prompt, i) => (
            <Flex
              key={`${i}_prompts`}
              flexDirection="column"
              className="pre-prompts-container"
              onClick={() => onPromptClick(_prompt.prompt)}
            >
              {_prompt.title === "SOP Co-Writer" ? (
                <p className="pre-prompt-title">
                  SOP <span className="co-writer-style">Co-Writer</span>
                </p>
              ) : (
                <p className="pre-prompt-title">{_prompt.title}</p>
              )}
              <p className="pre-prompt-text">{_prompt.text}</p>
              <img
                src={sr_prompt_icon}
                className="pre-prompt-arrow-img"
                alt="superrick-arrow-icon"
              />
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
    );
  };

  const renderChat = () => (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      className="rick-chat-holder"
    >
      <Flex className="rick-chat-limiter" flexDirection="column">
        {chat_session.map((chat, i) => (
          <Flex
            key={`${i}_chat`}
            flexDirection="row"
            alignItems="center"
            className="rick-chat-container"
          >
            {chat.from === "user" ? (
              <img
                src={sr_user_icon}
                height={40}
                className="from-user-img"
                alt="superrick-user-icon"
              />
            ) : (
              <img
                src={sr_icon}
                height={40}
                className="from-dolfin-img"
                alt="superrick-dolfin-icon"
              />
            )}
            <p className="rick-chat-text">{parse(chat.text)}</p>
          </Flex>
        ))}
        {is_loading ? (
          <Flex
            flexDirection="row"
            alignItems="center"
            className="rick-chat-container"
          >
            <img
              src={sr_icon}
              height={40}
              className="from-dolfin-img"
              alt="superrick-dolfin-icon"
            />
            <p className="rick-chat-text">
              <Spinner />
            </p>
          </Flex>
        ) : (
          <></>
        )}

        <Box
          height="1px"
          width="1px"
          backgroundColor="transparent"
          ref={last_chat_box}
        />
      </Flex>
    </Flex>
  );

  const renderInputActions = () => (
    <Flex
      className="rick-actions-container"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Flex className="rick-actions-limiter" alignItems="center">
        {/* user image */}

        <img
          src={sr_user_icon}
          height={40}
          className="self-user-image"
          alt="superrick-user-icon"
        />

        <form onSubmit={(e) => handleUserRequest(e)} className="superrick-form">
          {/* text */}
          <Input
            name="user_input"
            variant="filled"
            type="text"
            value={user_input_text}
            onChange={(e) => setUserInputText(e.target.value)}
            className="input-field"
            placeholder="Ask me about colleges, interviews tips or programs which you want to pursue"
          />
          {/* submit */}
          <DolfinButton
            disabled={is_loading}
            color_button={true}
            text="Submit"
            shadow={false}
            onButtonClick={(e) => handleUserRequest(e)}
          />
        </form>
      </Flex>
    </Flex>
  );

  return (
    <Flex
      className="rick-container"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex
        className="modal-back-holder"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <Flex
          className="modal-back-container"
          alignItems="center"
          flexDirection="row"
        >
          <Tooltip label="Go Back to Dolfin">
            <Link to="/">
              <img
                src={modal_back_icon}
                height={44}
                className="modal-back-icon"
                alt="modal-back-icon"
                onClick={() => {}}
              />
            </Link>
          </Tooltip>
          {chat_session.length > 0 ? (
            <p className="superrick-modal-breadcrumb">SuperRick</p>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      {
        // if no chat, then render the predefined prompts
        chat_session.length === 0 ? renderPrompts() : renderChat()
      }
      {renderInputActions()}
    </Flex>
  );
};

export default SuperRick;
