"use client";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Checkbox,
  Box,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import useUserStore from "../utils/commonStore";
import { DolfinButton } from "./Button";
import "styles/components.scss";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { dream_countries, email_regex, phone_regex, preferred_intakes, programs } from "utils/constants";
import { bookSessionAction } from "utils/modalActions";

const BookSession = () => {
  const toast = useToast();
  const { is_mobile_view, book_modal_visible, setBookModalVisibility } =
    useUserStore((state) => ({
      is_mobile_view: state.is_mobile_view,
      book_modal_visible: state.book_modal_visible,
      setBookModalVisibility: state.setBookModalVisibility,
    }));

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").required("Required"),
    email: Yup.string()
      .matches(email_regex, "Invalid Email")
      .required("Required"),
    phone: Yup.string()
      .matches(phone_regex, "Invalid Phone Number")
      .required("Required"),
    dream_country: Yup.string().required("Required"),
    program: Yup.string().required("Required"),
    intakes: Yup.array()
      .min(1, "Select at least one preferred Intake")
      .required("Required"),
  });

  const submitForm = async (form_data) => {
    const status = await bookSessionAction(form_data);
    setBookModalVisibility(false);
    if (status) {
      toast({
        title: "Form Submitted",
        status: "success",
        isClosable: true,
      });
    } else {
      toast({
        title: "Something went wrong. Please try again",
        status: "error",
        isClosable: true,
      });
    }
  };

  const renderContent = () => (
    <ModalBody className="dolfin-modal-body">
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        className="modal-head"
      >
        <p className="title">Book a Session</p>
        {is_mobile_view ? (
          <></>
        ) : (
          <p className="details">
            To help yourself choose the most suitable course and college and get
            answers to all the queries related to lifestyle, finance, visa, etc.
          </p>
        )}
      </Flex>
      <div className="modal-fields">
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            dream_country: "",
            program: "",
            intakes: [],
          }}
          validationSchema={ValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form>
              {/* name */}
              <Field name="name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">Name</FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* email */}
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Email
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* phone */}
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Phone Number
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Input
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          type="text"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* dream country */}
              <Field name="dream_country">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.dream_country && form.touched.dream_country
                    }
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Choose your dream country
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Select
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          placeholder="Select option"
                        >
                          {dream_countries.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {form.errors.dream_country}
                        </FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* program */}
              <Field name="program">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.program && form.touched.program}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems={is_mobile_view ? "flex-start" : "center"}
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          Which program you want to pursue?
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Select
                          {...field}
                          className="modal-form-input"
                          variant="filled"
                          placeholder="Select option"
                        >
                          {programs.map((program) => (
                            <option key={program} value={program}>
                              {program}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {form.errors.program}
                        </FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>
              {/* intake */}
              <Field type="checkbox" name="intakes">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.intakes && form.touched.intakes}
                  >
                    <Flex
                      className="modal-form-field"
                      flexDirection={is_mobile_view ? "column" : "row"}
                      alignItems="flex-start"
                    >
                      <Flex flex={1} className="modal-form-field-holder">
                        <FormLabel className="modal-form-label">
                          What’s your preferred intake?
                        </FormLabel>
                      </Flex>
                      <Flex
                        flex={1}
                        flexDirection="column"
                        className="modal-form-field-holder"
                      >
                        <Stack spacing={1}>
                          {preferred_intakes.map((intake) => (
                            <Checkbox
                              {...field}
                              key={intake}
                              value={intake}
                              colorScheme="gray"
                              className="modal-form-checkbox"
                            >
                              {intake}
                            </Checkbox>
                          ))}
                        </Stack>
                        <FormErrorMessage>
                          {form.errors.intakes}
                        </FormErrorMessage>
                      </Flex>
                    </Flex>
                  </FormControl>
                )}
              </Field>

              <Flex className="modal-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Cancel"
                  small={true}
                  button_type="reset"
                  onButtonClick={() => setBookModalVisibility(false)}
                />
                <Box width="30px" />
                <DolfinButton
                  shadow={false}
                  text="Submit"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </div>
    </ModalBody>
  );

  return (
    <>
      <Modal
        isOpen={book_modal_visible}
        onClose={() => setBookModalVisibility(false)}
      >
        <ModalOverlay />
        <ModalContent
          maxH={is_mobile_view ? "none" : "800px"}
          maxW={is_mobile_view ? "calc(100vw - 30px)" : "750px"}
        >
          {renderContent()}
        </ModalContent>
      </Modal>
    </>
  );
};

export default BookSession;
