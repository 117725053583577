import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from 'pages/Home';
import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import CoWriter from 'pages/CoWriter';
import CoWriterResult from 'pages/CoWriterResult';
import SuperRick from 'pages/SuperRick';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "talk-to-superrick",
    element: <SuperRick />
  },
  {
    path: "co-writer",
    element: <CoWriter />,
  },
  {
    path: "co-writer/:sopId",
    element: <CoWriterResult />,
  },
]);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
