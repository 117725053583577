import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import useUserStore from "utils/commonStore";


// the only tried and tested way
// to sync the formik values with the state values in realtime
const ValueTracker = () => {
	const {values} = useFormikContext();
	// store
	const { setSOPCOWFormData } = useUserStore((state) => ({
    setSOPCOWFormData: state.setSOPCOWFormData,
  }));
	useEffect(() => {
		setSOPCOWFormData(values);
	}, [values]);
	return <></>;
}

export default ValueTracker
