"use client";
import React from "react";
import logo from "images/logo.svg";
import menu from "images/menu.png";
import "styles/components.scss";
import useUserStore from "../utils/commonStore";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const NavBar = () => {
  const { is_mobile_view, nav_drawer_visible, setNavDrawerVisibility } =
    useUserStore((state) => ({
      is_mobile_view: state.is_mobile_view,
      nav_drawer_visible: state.nav_drawer_visible,
      setNavDrawerVisibility: state.setNavDrawerVisibility,
    }));

  const nav_options = [
    {
      title: "Home",
      active: true,
      extra_text: "",
      link: "#",
    },
    {
      title: "SOP Co-Writer",
      active: false,
      extra_text: "",
      link: "/co-writer",
    },
    {
      title: "Study Abroad",
      active: false,
      extra_text: "Coming Soon",
      link: "#",
    },
    {
      title: "Study in India",
      active: false,
      extra_text: "Coming Soon",
      link: "#",
    },
  ];
  return (
    <nav className="navbar-container">
      <div className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </div>
      {is_mobile_view ? (
        <div
          className="menu-container"
          onClick={() => setNavDrawerVisibility(true)}
        >
          <img src={menu} className="d-menu" alt="menu-icon" />
        </div>
      ) : (
        <div className="navbar-list">
          {nav_options.map((nav_item, i) => (
            <React.Fragment key={nav_item.title}>
              <div className="navigation-container">
                <Link
                  to={nav_item.link}
                  className={`navigation-text navigation-text-${nav_item.active}`}
                >
                  {nav_item.title}
                </Link>
                {nav_item.extra_text.length > 0 ? (
                  <div className="navigation-extra-text">
                    {nav_item.extra_text}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {nav_options.length - 1 !== i ? (
                <Box key={i} width={30} />
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </nav>
  );
};

export default NavBar;
