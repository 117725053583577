import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import { Field, Form, Formik } from "formik";
import useUserStore from "utils/commonStore";
import * as Yup from "yup";
import {
  dream_countries,
  email_regex,
  phone_regex,
  programs,
} from "utils/constants";
import "styles/cowriter.scss";
import { useState } from "react";
import ValueTracker from "./ValueTracker";

const COWStep1 = () => {
  const initial_form_values = {
    name: "",
    email: "",
    phone: "",
    college_name: "",
    country: "",
    program: "",
    background_details: "",
    course: "",
    why_university: "",
    why_program: "",
  };
  const [initial_values, setInitialValues] = useState(initial_form_values);
  const {
    sop_cow_form_data,
    setSOPCOWFormData,
    resetSOPCOWFormData,
    setSOPCOWStep,
  } = useUserStore((state) => ({
    sop_cow_form_data: state.sop_cow_form_data,
    setSOPCOWFormData: state.setSOPCOWFormData,
    resetSOPCOWFormData: state.resetSOPCOWFormData,
    setSOPCOWStep: state.setSOPCOWStep,
  }));

  useEffect(() => {
    if (sop_cow_form_data && Object.keys(sop_cow_form_data).length > 0) {
      setInitialValues(sop_cow_form_data);
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().matches(email_regex, "Invalid Email"),
    phone: Yup.string()
      .matches(phone_regex, "Invalid Phone Number")
      .required("Required"),
    college_name: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    program: Yup.string().required("Required"),
    course: Yup.string().required("Required"),
    background_details: Yup.string().required("Required"),
    why_university: Yup.string().required("Required"),
    why_program: Yup.string().required("Required"),
  });

  const submitForm = async (form_data) => {
    setSOPCOWFormData(form_data);
    setSOPCOWStep("next");
  };

  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h5 className="cow-title">Personal & University details</h5>
        <p className="cow-step-indicator">Step 1 of 5</p>
      </Flex>

      <Flex flex={1} flexDirection="column" className="cow-form-frame">
        <Formik
          initialValues={initial_values}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form className="cow-form">
              <SimpleGrid columns={3} spacing={"12px"} className="">
                {/* name */}
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Name
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* email */}
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Email
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* phone */}
                <Field name="phone">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.phone && form.touched.phone}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Phone Number
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>

                {/* college */}
                <Field name="college_name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.college_name && form.touched.college_name
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Name of the College / University
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.college_name}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>

                {/* dream country */}
                <Field name="country">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.country && form.touched.country}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Country
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Select
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder="Select option"
                          >
                            {dream_countries.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.country}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* program */}
                <Field name="program">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.program && form.touched.program}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            What program are you applying for?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Select
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder="Select option"
                          >
                            {programs.map((program) => (
                              <option key={program} value={program}>
                                {program}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.program}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>

                {/* course */}
                <Field name="course">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.course && form.touched.course}
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Course / Specialization
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.course}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* background_details */}
                <Field name="background_details">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.background_details &&
                        form.touched.background_details
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Tell us about your background
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Input
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            type="text"
                          />
                          <FormErrorMessage>
                            {form.errors.background_details}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>
              <SimpleGrid columns={1} spacing={"12px"} className="cow-grid-2">
                {/* why_university */}
                <Field name="why_university">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.why_university &&
                        form.touched.why_university
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Why are you excited to join this college /
                            university?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.why_university}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* why_program */}
                <Field name="why_program">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.why_program && form.touched.why_program
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            Why are you excited to join this program?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.why_program}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>

              <Flex className="cow-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Reset"
                  xsmall={true}
                  button_type="reset"
                  onButtonClick={() => {
                    resetSOPCOWFormData();
                    props.handleReset();
                  }}
                />
                <Box width="16px" />
                <DolfinButton
                  no_border={true}
                  xsmall={true}
                  shadow={false}
                  text="Next"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
              <ValueTracker />
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default COWStep1;
