import axios from "axios";
import endpoints from "./endpoints";

export const bookSessionApi = async (booking_data) => {
  console.warn("called ...bookSessionApi");
  const payload = {
    name: booking_data["name"],
    destination_country: booking_data["dream_country"],
    degree_to_pursue: booking_data["program"],
    preferred_intake: booking_data["intakes"],
    email: booking_data["email"],
    mobile_no: booking_data["phone"],
  };
  await axios({
    url: endpoints.book_session.url,
    method: endpoints.book_session.method,
    data: payload,
  });
};

export const shareFeedbackApi = async (feedback_data) => {
  console.warn("called ...shareFeedbackApi");
  const payload = {
    name: feedback_data["name"],
    message: feedback_data["message"],
    email: feedback_data["email"],
    mobile_no: feedback_data["phone"],
    is_anon: true,
  };
  await axios({
    url: endpoints.share_feedback.url,
    method: endpoints.share_feedback.method,
    data: payload,
  });
};

export const subscribeApi = async () => {
  console.warn("called ...subscribeApi");
  await axios({
    url: endpoints.subscribe.url,
    method: endpoints.subscribe.method,
  });
};

export const conversationApi = async (user_query) => {
  console.warn("called ...conversationApi");
  let dolfin_response = "";
  const payload = {
    data: {
      message: user_query,
    },
  };
  const resp = await axios({
    url: endpoints.conversation.url,
    method: endpoints.conversation.method,
    data: payload,
  });
  if (resp && resp.data && resp.data.message) {
    dolfin_response = resp.data.message;
  }
  return dolfin_response;
};

// generate the sop content
export const generateSOPApi = async (payload) => {
  console.warn("called ...generateSOPApi");
  let sop_response = "";
  const resp = await axios({
    url: endpoints.generate_sop.url,
    method: endpoints.generate_sop.method,
    data: payload,
  });
  if (resp && resp.data && resp.data.uuid) {
    sop_response = resp.data.uuid;
  }
  return sop_response;
};

// get the sop content
export const getGeneratedSOPApi = async (sop_generated_id) => {
  console.warn("called ...getGeneratedSOPApi");
  let sop_response = "";
  const payload = {
    data: {
      message: sop_generated_id,
    },
  };
  let url = endpoints.get_sop.url.replace('{{ID}}', sop_generated_id)
  const resp = await axios({
    url,
    method: endpoints.get_sop.method
  });
  if (resp && resp.data && resp.data.message) {
    sop_response = resp.data.message;
  }
  return sop_response;
};
