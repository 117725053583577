"use client";
import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, Flex } from "@chakra-ui/react";
import useUserStore from "utils/commonStore";
import { DolfinButton } from "./Button";
import "styles/components.scss";
import success_icon from "images/success-icon.png";

const ThankYouModal = () => {
  const {
    thank_you_title,
    thank_you_detail,
    is_mobile_view,
    thank_you_modal_visible,
    setThankYouModalVisibility,
  } = useUserStore((state) => ({
    thank_you_title: state.thank_you_title,
    thank_you_detail: state.thank_you_detail,
    is_mobile_view: state.is_mobile_view,
    thank_you_modal_visible: state.thank_you_modal_visible,
    setThankYouModalVisibility: state.setThankYouModalVisibility,
  }));

  return (
    <>
      <Modal
        isOpen={thank_you_modal_visible}
        onClose={() => setThankYouModalVisibility(false)}
      >
        <ModalOverlay />
        <ModalContent
          maxH={is_mobile_view ? "none" : "800px"}
          maxW={is_mobile_view ? "calc(100vw - 30px)" : "550px"}
        >
          <ModalBody className="dolfin-modal-body">
            <Flex flexDirection="column" alignItems="center">
              <img
                src={success_icon}
                className="modal-success-icon"
                alt="modal-success-icon"
              />
              <p className="title">{thank_you_title}</p>
              <p className="details">
                {thank_you_detail}
              </p>
              <DolfinButton
                color_button={true}
                shadow={false}
                text="Close"
                onButtonClick={() => setThankYouModalVisibility(false)}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ThankYouModal;
