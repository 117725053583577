import React, { useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { DolfinButton } from "components/Button";
import { Field, Form, Formik, useFormikContext } from "formik";
import useUserStore from "utils/commonStore";
import ValueTracker from "components/cow/ValueTracker";
import * as Yup from "yup";
import "styles/cowriter.scss";
import { useState } from "react";

const COWStep2 = () => {
  const initial_form_values = {
    short_term_goals: "",
    long_term_goals: "",
  };
  const [initial_values, setInitialValues] = useState(initial_form_values);
  const { sop_cow_form_data, setSOPCOWFormData, setSOPCOWStep } = useUserStore(
    (state) => ({
      sop_cow_form_data: state.sop_cow_form_data,
      setSOPCOWFormData: state.setSOPCOWFormData,
      setSOPCOWStep: state.setSOPCOWStep,
    })
  );

  useEffect(() => {
    if (sop_cow_form_data && Object.keys(sop_cow_form_data).length > 0) {
      setInitialValues(sop_cow_form_data);
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    short_term_goals: Yup.string().required("Required"),
    long_term_goals: Yup.string().required("Required"),
  });

  const submitForm = async (form_data) => {
    setSOPCOWFormData(form_data);
    setSOPCOWStep("next");
  };

  return (
    <Flex flexDirection="column" className="cow-form-container">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h5 className="cow-title">Short-term and Long-term Goals</h5>
        <p className="cow-step-indicator">Step 2 of 5</p>
      </Flex>

      <Flex flex={1} flexDirection="column" className="cow-form-frame">
        <Formik
          initialValues={initial_values}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <Form className="cow-form">
              <SimpleGrid columns={1} spacing={"12px"} className="">
                {/* short term goals */}
                <Field name="short_term_goals">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.short_term_goals &&
                        form.touched.short_term_goals
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            What are your short-term goals?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            rows={5}
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.short_term_goals}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
                {/* long term goals */}
                <Field name="long_term_goals">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.long_term_goals &&
                        form.touched.long_term_goals
                      }
                    >
                      <Flex
                        className="modal-form-field"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Flex flex={1} className="modal-form-field-holder">
                          <FormLabel className="modal-form-label">
                            What are your long-term goals?
                          </FormLabel>
                        </Flex>
                        <Flex
                          flex={1}
                          flexDirection="column"
                          className="modal-form-field-holder"
                        >
                          <Textarea
                            rows={5}
                            {...field}
                            className="modal-form-input"
                            variant="filled"
                            placeholder=""
                          />
                          <FormErrorMessage>
                            {form.errors.long_term_goals}
                          </FormErrorMessage>
                        </Flex>
                      </Flex>
                    </FormControl>
                  )}
                </Field>
              </SimpleGrid>

              <Flex className="cow-footer" alignItems="center">
                <DolfinButton
                  light={true}
                  shadow={false}
                  text="Back"
                  xsmall={true}
                  onButtonClick={() => setSOPCOWStep("back")}
                />
                <Box width="16px" />
                <DolfinButton
                  no_border={true}
                  xsmall={true}
                  shadow={false}
                  text="Next"
                  color_button={true}
                  onButtonClick={() => {}}
                />
              </Flex>
              <ValueTracker />
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default COWStep2;
